@mixin textCta() {
    font-size: 18px;
    font-weight: $fonts__semibold;
    line-height: 129%;
    letter-spacing: 0.02125em;

    &::after {
        @include font-family-icomoon();
        content: $icon__chevron-small-right;
        font-size: 24px;
        display: inline-block;
        position: relative;
        top: 4px;
        margin-left: 4px;
    }
}

// values
// color: navy (default), cyan, white, orange
// style: transparent (default), solid
@mixin buttonCta($color: 'navy', $style: 'transparent') {
    font-size: 15px;
    line-height: 1;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    font-weight: $fonts__semibold;
    padding: 18px 44px;
    border-width: 2px;
    border-style: solid;
    transition: $transition__timing--fast $transition__ease all;
    
    @if $style == 'transparent' {

        @if $color == 'navy' {
            color: $colors__navy;
            border-color: $colors__navy;
    
            &:hover, &:active, &:focus {
                color: #F9A36A;
                background-color: $colors__navy;
            }
        }
            
        @if $color == 'cyan' {
            color: $colors__cyan;
            border-color: $colors__cyan;
    
            &:hover, &:active, &:focus {
                color: $colors__white;
                background-color: $colors__cyan;
            }
        }

        @if $color == 'white' {
            color: $colors__white;
            border-color: $colors__white;
    
            &:hover, &:active, &:focus {
                color: $colors__navy;
                background-color: $colors__white;
            }
        }

        @if $color == 'orange' {
            color: #F9A36A;
            border-color: #F9A36A;
    
            &:hover, &:active, &:focus {
                color: $colors__navy;
                background-color: #F9A36A;
            }
        }
    }

    @if $style == 'solid' {
        @if $color == 'navy' {
            color: transparent;
            background-color: $colors__navy;
            
            &:hover, &:active, &:focus {
                color: $colors__cyan;
                border-color: $colors__cyan;
                background-color: transparent;
            }
        }
            
        @if $color == 'cyan' {
            color: $colors__white;
            background-color: $colors__cyan;
            border-color: $colors__cyan;
            
            &:hover, &:active, &:focus {
                color: $colors__cyan;
                background-color: transparent;
            }
        }

        @if $color == 'white' {
            color: $colors__navy;
            background-color: $colors__white;
            border-color: $colors__white;
    
            &:hover, &:active, &:focus {
                color: $colors__white;
                background-color: transparent;
            }
        }

        @if $color == 'orange' {
            color: $colors__navy;
            background-color: #F9A36A;
            border-color: #F9A36A;
    
            &:hover, &:active, &:focus {
                color: #F9A36A;
                background-color: transparent;
            }
        }
    }

}