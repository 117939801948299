.latest-insights-item {
    color: $colors__navy;
    transition: $transition__timing $transition__ease all;

    &__link {
        width: 100%;

        &:hover {
            .latest-insights-item__cta-link {
                color: $colors__navy;
            }
            .latest-insights-item__img-container {
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        &-container {
            position: relative;
            padding-bottom: 56.25%;
            overflow: hidden;
            background-color: $colors__coral;

            &:after {
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(270deg, rgba(18, 27, 40, 0.10) 0%, rgba(18, 27, 40, 0.90) 100%);
                opacity: 0;
                transition: .3s ease all;
            }
        }
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__body {

    }

    &__date {
        font-size: 14px;
        font-weight: $fonts__regular;
        line-height: 143%; 
        letter-spacing: 0.56px;
    }

    &__title {
        font-size: 20px;
        font-weight: $fonts__regular;;
        line-height: 133%; 
        margin: 12px 0;
    }

    &__eyebrow {
        font-size: 14px;
        font-weight: $fonts__semibold;
        line-height: 143%; 
        letter-spacing: 0.84px;
        text-transform: uppercase;
        margin-top: 6px;
    }

    &__cta {
        margin-top: 16px;
        &-link {
            @include textCta();
            color: $colors__cyan;
        }

    }
}