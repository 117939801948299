.scroll-to-top {
    text-align: right; 
    &__btn {
        display: inline-flex;
        align-items: center;
        gap: 18px;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0.64px;
        text-transform: uppercase;
        color: $colors__aqua;
        font-weight: $fonts__semibold;

        &::after {
            @include font-family-icomoon();
            content: $icon__upper-arrow;
            font-size: 36px;
        }

        &:hover, &:active {
            color: $colors__navy;

            span {
                text-decoration: underline;
            }
        }
    }

}