.contacts {
  width:100%;
  &__label {
    @extend .font__h2;
    margin-bottom: $mobile-vertical-padding;
  }
  
  &__abstract {
      @extend .font__body;
      margin-bottom: $mobile-vertical-padding;
	}

  &__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
   
  }

  @include above(tablet) {
    &__label {
      margin-bottom: $desktop-vertical-padding;
    }
	
	&__abstract {
	  margin-bottom: $desktop-vertical-padding;
	}

    &__container {
      margin: 0 -20px;
      flex-direction: row;
      &--two {
        .contact-card {
          width: 50%;
		  
          &__image {
            img {
              max-height: 303px;
            }
          }
        }
      }
      &--three {
        .contact-card {
          width: 33.33%;
        }
      }
    }
  }

  &__background-style {
    .contact-card {
      &__image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        &--hidden {
          visibility: hidden;
        }
      }
    }
  }
}

.contact-card {
  width: 100%;
  margin-bottom: 40px;
  color: $colors__navy;

  &__image {
    width: 100%;
    &--bg {
      padding-bottom: 56%;
      background-position: 91% 5%;
      background-size: 215%;
      background-repeat: no-repeat;
    }
    img {
      width: 100%;
	    max-height: 229px;
    }
  }

  &__name {
    @extend .font__body;
    @extend .font__body--semibold;
    margin-top: $mobile-vertical-padding;
  }

  &__title {
    @extend .font__subhead2;
    //margin-top: 28px;
    text-transform: uppercase;
  }

  &__subtitle {
    @extend .font__body2;
    color: $colors__gray;
  }

  &__top-content {
    margin-bottom: 15px;
  }

  &__text {
    @extend .font__body2;
  }

  &__city a {
    &+ .contact-card__city a {
      &:before {          
        content: ', ';                
        font-weight: 100;        
      }
    }
  }  

  &__email {
    margin-bottom: 24px;
  }

  &__cta {
    text-transform: uppercase;
  }

  @include above(tablet) {
    //flex-grow: 1;
   // margin-bottom: $desktop-vertical-padding;
    //margin: 0 10px $desktop-vertical-padding 10px;
    padding: 0 10px;
  }
}

.contact-card {
  &__city{
    &__link {
      &+ .contact-card__city__link{
        &:before {  
          content: ', ';                
          font-weight: 100;          
        }
      }
    }    
  } 
}

.contacts__featured {
  .contact-card {
    &__city{
      &__link {
        &+ .contact-card__city__link{
          &:before {  
            content: ', ';                
            font-weight: 100;          
            margin-left: -0.25rem;
          }
        }
      }    
    } 
  }
}

.team-landing {
  &__header {
    &__label {
      flex-grow: 1;
      @extend .font__h2;
      margin-bottom: $mobile-vertical-padding;
    }
	
	&__abstract {
      @extend .font__body;
      margin-bottom: $mobile-vertical-padding;
	}

    &__filters {
      display: flex;
      flex-direction: column;
    }
  }
  &__landing {
    margin: 0!important;
    //margin-bottom:$mobile-vertical-padding
    @include above(mobile) {
      display: grid!important;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px;
      margin: 0!important;
    }
    @include above(tablet) {
      grid-template-columns: repeat(3, 1fr);
    }
  }



  .contact-card {
    width: 100%;
    padding: 0;
    @include above(mobile) {
      margin: 0;
    }
  }

  @include above(tablet) {

    &__header {
      flex-direction: row;
	  &__filters {
        flex-direction: row;
      }
	  &__abstract {
		  margin-bottom: $desktop-vertical-padding;
		}
    }
    &__landing {
      flex-wrap: wrap;
      //margin-bottom:$desktop-vertical-padding;
    }
  }
}

.leadership {
  .contact-card__cta {
    display:none;
  }
}
