$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "/assets/public/fonts/icons" !default;

$icon__chevron-small-down: "\e90e";
$icon__chevron-small-left: "\e90f";
$icon__chevron-small-right: "\e910";
$icon__chevron-small-up: "\e911";
$icon__chevron-thin-down: "\e912";
$icon__chevron-thin-left: "\e913";
$icon__chevron-thin-right: "\e914";
$icon__chevron-thin-up: "\e915";
$icon__download: "\e900";
$icon__twitter: "\e918";
$icon__vimeo: "\e90c";
$icon__search: "\e909";
$icon__facebook: "\e902";
$icon__instagram: "\ea92";
$icon__linkedin: "\e903";
$icon__print: "\e907";
$icon__quotes: "\e908";
$icon__close: "\e901";
$icon__thin-close: "\e917";
$icon__external: "\e904";
$icon__menu: "\e905";
$icon__minus: "\e906";
$icon__plus: "\e90a";
$icon__share: "\e90d";
$icon__map: "\e916";
$icon__play3: "\ea1c";
$icon__upper-arrow: "\e919";