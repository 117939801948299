.embedded-video {
    @include container();
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include above(desktop) {
        gap: 72px;
    }

    &__section {
        background-color: $colors__navy;
        padding: 40px 0;
        position: relative;
        z-index: 1;

        @include above(desktop) {
            padding: 115px 0;
        }

        &--people {
            padding: 56px 0;
        }

        &--white {
            background-color: $colors__white;
        }
    }

    &--people {
        padding: 0;
        
        .embedded-video__title {
            text-align: left;
            width: 100%;
            font-size: clamp(17px, 4vw, 56px);
            line-height: 120%;
        }
        
        .embedded-video__desc-title {
            margin-bottom: 31px;
        }

        .embedded-video__desc {
            color: $colors__navy;
        }
    }

    &__desc {
        max-width: none;
        color: $colors__white;

        &-title {
            text-transform: uppercase;
            line-height: 133.5%; 
            letter-spacing: 0.96px;
            font-weight: $fonts__semibold;
            margin-top: 0.45em;
        }
        &-text {
            @extend .font__body;
            p, a, li, span {
                @extend .font__body;
                letter-spacing: 0.02em;
            }
            letter-spacing: 0.02em;
        }

        &--horizontal {
            display: flex;
            flex-wrap: wrap;
            gap: 31px;

            .embedded-video__desc-title {
                flex: 0 0 100%;
            }

            @include above(desktop) {
                flex-wrap: nowrap;
            }
        }

        @include above(desktop) {
            max-width: 90%;
            // &-text {
            //     font-size: 21px;
            //     line-height: 139%;
            // }

            &--horizontal {
                .embedded-video__desc-title {
                    flex: 0 0 30%;
                }
            }
        }
    }

    &__video-container {
        position: relative;
        padding-top: 70.25%;
        width: 100%;
        margin-left: -40px;
        margin-right: -40px;
        width: calc(100% + (40px * 2));
        min-height: 210px;
        overflow: hidden;
        
        @include above(desktop) {
            padding-top: 56.25%;
            margin-left: 0;
            margin-right: 0;
            width: 100%;
        }
    }

    &__video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    &__play {
        position: absolute;
        z-index: 6;
        border: 2px solid $colors__white;
        font-size: 30px;
        height: 50px;
        width: 50px;
        border-radius: 100%;
        color: $colors__white;
        text-align: center;
        z-index: 5;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &::before {
            height: 50px;
            width: 50px;
            line-height: 45px;
            display: block;
        }

        &:focus {
            outline: 2px solid white;
            outline-offset: 2px;
        }

        @include above(desktop) {
            font-size: 40px;
            height: 75px;
            width: 75px;    

            &::before {
                height: 75px;
                width: 75px;
                line-height: 70px;
                display: block;
            }
        }
    }

    &__title {
        line-height: 105%;
        font-size: clamp(17px, 4.5vw, 64px);
        color: $colors__white;
        font-weight: $fonts__semibold;
        z-index: 5;
        position: relative;
        top: calc(50% + 30px);

        @include above(mobile) { 
            top: calc(50% + 50px);            
        }
        
        @include above(desktop) {
            top: calc(50% + 75px);
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% + 1px);
        z-index: 1;
        text-align: center;
        padding: 30px;
        transition: .3s ease all;

        &--playing {
            opacity: 0;
            pointer-events: none;
            transition: .3s ease all;
        }

        &::after, &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            height: 100%;
            width: 100%;
            background: linear-gradient(180deg, rgba(30, 40, 60, 0.17) -35.9%, rgba(18, 27, 45, 0.69) 55.83%);
            transition: $transition__timing $transition__ease all;
        }

        &::before {
            opacity: 0;
            z-index: 2;
            transition: $transition__timing $transition__ease all;
        }   

        &:hover {
            &::before {
                opacity: 1;
            }
        }

        @include above(desktop) {
            padding: 60px;
        }
    }

    &__placeholder {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -5;
        object-fit: cover;
    }
    
}