.featured-events {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: $colors__navy;
        opacity: .5;
        top: 0;
        left: 0;
    }

    &__content {
        @include container();
        padding-top: 80px;
        padding-bottom: 80px;
        position: relative;
        z-index: 1;
    }
    &__title {
        color: $colors__white;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.06em;
        font-weight: $fonts__semibold;
        text-transform: uppercase;
        margin-bottom: 60px;
    }

    &__events {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 24px;

        &:has(.featured-events__events-item:nth-child(3)) {
            .featured-events__events-item {
                @include above(desktop) {
                    flex: 1 1 calc((100% / 3) - 40px);
                }
            }
        }

        &-item {
            flex: 1 1 100%;

            @include above(tablet) {
                flex: 0 1 calc(50% - 40px);
            }

            @include above(desktop) {
                flex: 0 1 calc((100% / 3) - 40px);
            }
        }
    }

    &__cta {
        text-align: center;
        margin-top: 60px;
        @include above(desktop) {
            text-align: left;
        }

        &-link {
            @include buttonCta('white', 'transparent');
        }
    }
}