.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    background: white;
    border: 2px solid black;
    box-sizing: content-box;
  }

  // Box hover
  &:hover + label:before {
    border-color: $colors__teal;
  }


  // Box checked
  &:checked + label:before {
    border-color: $colors__black;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 1px;
    background: $colors__white;
    width: 5px;
    height: 10px;
    border-right: 2px solid $colors__black;
    border-bottom: 2px solid $colors__black;
    transform: rotate(45deg);
  }
}

