.people-search {
  width:100%;

  .contact-card__email {
    margin-bottom: 0;
  }
  
  &__header {
    @extend .font__subhead2;
  }
  &__filters {
    margin-top: $mobile-vertical-padding;
    margin-bottom: $mobile-vertical-padding;

    &--search {

    }
    &--dropdown {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;
      padding-bottom: 48px;
      border-bottom: 1px solid $colors__gray--light;
    }
    &__container {
      width: 100%;
    }
    &__filter {
      margin: 0px;
    }
    
  }
  &__search {
    @extend .icon;
    color: $colors__navy;
    font-weight: $fonts__regular;
    font-size: 24px;
    &__button {
      &:before {
        content: $icon__search;
        cursor: pointer;
        align-items: center;
        color: $colors__aqua;
        display: flex;
        font-family: 'icomoon' !important;
        font-size: 21px;
      }
    }
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
   &__input {
    @extend .font__subhead1;
    @extend .font__subhead1--extralight;
    margin-left: 22px;
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
    padding: 14px 0;
    transition: .3s ease all;

    &:placeholder-shown {
      & + span {
        display: none;
      }
    }

    &:hover {
      &::placeholder {
        opacity: 1;
      }
    }
    &::placeholder {
      @extend .font__subhead1;
      @extend .font__subhead1--extralight;
      color: $colors__navy;
      font-size: 26px;
      line-height: 32px;
      font-weight: $fonts__regular;
      opacity: .5;
    }
   
   }
   &:focus-within {
    border-color: $colors__aqua;
  } 
    border-bottom: 2px solid $colors__aqua;
  }
  &__people {
    margin-top: $mobile-vertical-padding;
    scroll-margin-top: 80px; //navbar height
    height: 0;
    overflow: hidden;
    transition: .8s $transition__ease all;
    &--has-results {
      margin-bottom: 60px;
      transition: .8s $transition__ease all;
    }
    &__no-results {
      @extend .font__body;
      @extend .font__body--semibold;
    }
  }
  &__clear {
    color: $colors__aqua;
    text-decoration: underline;
    font-size: 16px;
    padding: 10px 0;

    &:hover {
      color: $colors__navy;
    }
  }
  &__see-all {
    margin-top: $mobile-vertical-padding;
    &__button {
      font-weight: $fonts__semibold;
      color: $colors__blue--dark;
      display: flex;
      align-items: center;

      &--disabled {
        pointer-events: none;
        color: $colors__gray--light;
      }

      &-icon {
        font-weight: $fonts__semibold;
        font-size: 24px;
      }

      &:hover {
        & > span:first-child {
          text-decoration: underline;
        }
      }
    }
  }
  @include above(desktop) {


    &__filters {
      flex-direction: row;
      &__left {
        flex-basis: 33.33%;
      }
      &__right {
        flex-basis: 56.66%;
      }
      &__container {
        // max-width: 200px;
        flex: 0 1 calc((100%/6) - 20px);
      }
      &__filter {
        width: 100%;
        margin-top: 0px;
      }
     
    }
    &__search {
      width: 350px;
    }
  }

  &__more {
    margin-top: 60px;
    padding-bottom: 10px;
    &__button {
      color: $colors__cyan;
      border-color: $colors__cyan;
    }
  }

  &__active-filters {
    margin-bottom: 48px;
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 14px;
    
    @include above(tablet) {
      justify-content: space-between;
    }

    &-btns {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      flex: 1 1 100%;
  
      @include above(tablet) {
        flex: 1 1 auto;
      }
    }

    &-checkboxes {
      display: flex;
      flex-wrap: wrap;
      gap: 12px 32px;
      flex: 1 1 100%;
      margin-top: 24px;
  
      @include above(tablet) {
        flex: 1 1 auto;
        margin-top: 12px;
      }
    }
    
    &-list {
    }

    &-filter {
      display: flex;
      align-items: center;
      gap: 20px;
      border: 1px solid $colors__lines;
      padding: 10px 12px;
      font-size: 14px;
      color: $colors__navy;

      &:hover, &:focus {
        border-color: $colors__navy;
      }

      .icon {
        font-size: 12px;
      }

      &-label {
        
      }
    }
  }


  &__filters__school {
    padding: 8px 0;
    flex: 1 1 100%;

    @include above(tablet) {
      flex: 0 0 auto;
    }
  }
}