.expertise {
  &-title {
    text-transform: uppercase;
    color: $colors__navy;
    font-weight: $fonts__semibold;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.09em;
    margin-bottom: 40px;
  }

  &__main {
    gap: 54px;
    // flex-direction: column-reverse;
  }

  &__side-nav {

    width: 50%;
    padding: 1rem;

    //height: 80vh;
    //overflow-y: hidden;    
    &__container {
      &--sticky {
        position: fixed;
        top: 135px;
      }

      &__header {
        margin-top: 5px;
        @extend .font__body;
        @extend .font__body--semibold;
      }

      &__text {
        margin-bottom: 20px;
      }
    }
  }

  &__content {
    padding-top: 60px;
    padding-bottom: 104px;
  }

  &__scroll-to-top {
    margin-top: 60px;
  }

  &__tabs {
    
  }

  &__tab {
    &--practices-industries {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    &--az {
    }
  }

  &__nav {
    width: 100%;
    margin-bottom: 60px;

    &-list {
      overflow: hidden;
      border: 1px solid #1E283C40;
      background-color: $colors__smoke;
      padding: 3px;
      border-radius: 50px;
      display: flex;
    }

    &-btn {
      color: #1E283CBF;
      flex: 1 1 50%;
      font-weight: $fonts__semibold;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1em;
      border-radius: 50px;
      text-align: center;
      background-color: $colors__smoke;
      padding: 10px;

      @include above(mobile) {
        font-size: 15px;
      }

      &--active {
        color: $colors__white;
        background-color: $colors__navy;
      }
    }
  }


  &__main {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  @include above(tablet) {
    &__main {
      flex-wrap: nowrap;
    }

  }

  // a-z services list styles
  &__alpha {
    margin: 60px 0 30px 0;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    row-gap: 30px;

    @include above(mobile) {
      grid-template-columns: repeat(13, 1fr);
      row-gap: 30px;
    }

    @include above(tablet) {
      grid-template-columns: repeat(26, 1fr);
      row-gap: 0px;
    }

    &::after {
      content: "";
      flex: auto;
    }

    &-letter {
      font-size: 20px;
      line-height: 20px;
      color: $colors__aqua;
      text-align: center;

      @include above(tablet) {}

      &:hover {
        text-decoration: underline;
      }

      &--disabled {
        color: $colors__gray--light;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &-items {
      @include above(tablet) {
        columns: 2;
        column-gap: 58px;
      }
    }

    &-item {
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid $colors__gray--light;
      padding: 24px 0;

      &:first-child {
        padding-top: 0;
      }

      @include above(mobile) {
        &:first-child {
          padding-top: 24px;
        }
      }

      &-letter {
        font-size: 24px;
        text-transform: uppercase;
        line-height: 42%;
        font-weight: $fonts__semibold;
        color: $colors__navy;
        padding: 10px 0;
        text-align: center;
        box-sizing: border-box;
        width: auto;
        
        @include above(mobile) {
          padding: 10px;  
          width: 40px;
        }
      }
      &-content {
        display: flex;
        align-items: flex-start;
        gap: 22px;
        flex-wrap: wrap;

        @include above(mobile) {
          flex-wrap: nowrap;
        }
      }
      &-list {
        flex: 1 1 100%;
        li {
          margin: 16px 0;

          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }

        @include above(mobile) {
          flex-wrap: nowrap;
          flex: 1 1 auto;
        }
      }
      &-link {
        color: $colors__blue--dark;
        font-weight: $fonts__regular;
        font-size: 16px;
        line-height: 129%;
        letter-spacing: 0.32px;
        vertical-align: middle;

        span {
          padding-right: 28px;
        }

        &::after {
          @include font-family-icomoon();
          content: $icon__chevron-small-right;
          font-size: 24px;
          margin-left: -24px;
          vertical-align: middle;
          display: inline-block;
        }

        &:hover, &:focus {
          text-decoration: underline;
        }
      }
    }
  }

}

.industries {}

.expertise__section {
  //padding:20px;
  flex: 1 1 50%;

  @include above(tablet) {
    // width: 50%;
  }
}

.practices {
  @include above(tablet) {
    // padding-left: 75px;
  }
}

.expertise-item {
  // margin: 20px 0;
  //padding-right: 40px;
  cursor: pointer;

  .link {
    width: 100%;

    &:after {
      content: none;
    }

    &:hover {
      text-decoration: underline;
      color: $colors__aqua;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    //justify-content: space-between;
  }

  &__subnav {
    overflow: hidden;

    ul {
      padding: 10px 0;
      border-bottom: 1px solid $colors__gray--light;
    }
  }

  &__secondary-subnav {
    margin-left: 15px;
    &#{&} {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__header {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .05em;
    text-transform: uppercase;
    font-weight: $fonts__regular;
    border-bottom: 1px solid $colors__gray--light;
    padding: 20px 40px 20px 0;
    color: $colors__navy;
    width: 100%;
    box-sizing: border-box;


    &__name-part {
      white-space: nowrap;

      &:after {
        font-size: 30px;
        vertical-align: middle;
        margin-left: 10px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &--closed {
      .expertise-item__header__name-part {
        &:after {
          @include font-family-icomoon();
          content: $icon__chevron-small-down;
        }
      }


    }

    &--open {
      .expertise-item__header__name-part {
        &:after {
          @include font-family-icomoon();
          content: $icon__chevron-small-up;

        }
      }


    }

    &--active {}

    &--inactive {}
  }

}

.expertise-accordion-item {
  margin: 16px 0;
  overflow: hidden;
  cursor: pointer;

  &__header {
    color: $colors__blue;

    &--active {
      @extend .font__body2--semibold;

    }

    &--inactive {
      @extend .font__body2;

    }
  }

  @include above(desktop) {
    &__side-nav {
      width: 30%;
    }
  }

}