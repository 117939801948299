.navbar {

  $spacing-x: 20px;
  $spacing-y: 20px;
  $logo-width: 161px;

  background-color: $colors__white;
  box-sizing: border-box;
  padding: $spacing-y 0;

  padding-top: 0;
  box-shadow: 0px 0px 12px 0px rgba(30, 40, 60, 0.10);

  &--fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    transition: top 1s;
  }

  // &--home {
  //   position: relative;
  // }

  &__hidden{
    display: none;
  }

  &--hide {
    top: -66px;
    transition: top 1s;
  }

  &--fixed-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 5;

    //for homepage, mostly
    box-shadow: 0px 0px 16px -3px $colors__black;
  }

  &__page {
    padding-top: $navbar-height;
  }

  &__page-bottom {
    height: 100%;
    top: auto;
    height: calc(100% - 71px);

    &--active{
      //padding-top: $navbar-height;
      //padding-top: 71px;      
    }    
  }

  &__container {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;

    padding-top: 1.25rem;
  }

  &__left {
    flex: 0 1 auto;
    margin-right: 60px;
  }

  // For centering center links
  &__right {
    flex: 1;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }

  &__brand {
    width: $logo-width;
    @include fadeOnHover();    
    img {
      width: 100%;
    }
  }

  &__links {
    display: none;
    position: relative;
    margin-right: 36px;
  }

  &__link {
    &:hover {
      color: $colors__blue--dark;
    }

    margin-left: $link-spacing;

    &--primary {
      @extend .font__body2;
      @extend .font__body2--semibold;
      margin-left: 34px;

      &.navbar__link {
        letter-spacing: 0.03em;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    &--secondary {
      @extend .font__body3;
      margin-left: 24px;
      &.navbar__link {
        letter-spacing: 0.03em;
      }
    }

    &--active {
      color: $colors__blue--dark;
    }
  }

  &__icon {
    font-size: 18px;
    display: block;

    &__search {
      position: relative;
      z-index: 9;
    }
  }

  &__menu {
    @include fadeOnHover();
  }

  @include above(desktop) {
    &__menu {
      display: none;
    }

    &__links {
      display: flex;
      align-items: center;
    }
  }
}

.navbar-search {
  display: none;
  z-index: 8;
  flex: 1 1 300px;
  &__container {
    position: relative;
    display: flex;
    background: $colors__white;
    border: 1px solid $colors__gray--light;
    overflow: hidden;
    .navbar__link--secondary{
      flex-shrink: 0;
      margin-left: 10px;
    }
  }

  @include above(desktop) {
    display: block;
  }
}

header.navbar {
  height: auto;
}