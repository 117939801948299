.featured-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    &:has(> :nth-child(5)) {
        .featured-card {
            flex-grow: 0;
        }
    }
}



.home-featured-cards {
    position: relative;
    transition: $transition__timing $transition__ease all;

    &--spacer {
        display: block;
        pointer-events: none;
        height: 95vh;
        z-index: -2;
    }

    &__diag {
        position: absolute;
        top: -7vw;
        left: 0%;
        width: 100%;
        height: 70%;
        box-shadow: 0px -11px 32px 0px #121B2DB2;
        background-color: $colors__navy;
        transform: skew(0, -7deg) translateY(50px);
        z-index: 0;

        &::before {
            content: '';
            position: absolute;
            top: 100px;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $colors__navy;
            z-index: 1;
        }
    }

    background-color: $colors__navy;
    &__content {
        @include container();
        position: relative;
        z-index: 2;

        .section {
            padding-bottom: 28px;
        }
    }
}