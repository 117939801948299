.stats-list {
    @include container();

    &__container {
        background-color: $colors__white;
        position: relative;
        z-index: 1;
        padding-top: 40px;
        padding-bottom: 60px;

        @include above(tablet) {
            padding-top: 120px;
            padding-bottom: 120px;
        }
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        margin-top: 60px;

        &:has(.stat:nth-child(5)) {
            justify-content: center;
            .stat {
                @include above(tablet) {
                    flex: 1 1 50%;
                }

                @include above(desktop_plus) {
                    flex: 0 1 25%;
                }
            }
        }
    }
    &__title {
        text-transform: uppercase;
        letter-spacing: 0.06em;
        line-height: 21px;
        font-weight: $fonts__semibold;
    }
    
    @include above(tablet) {
        &__content {
            row-gap: 90px;
        }
    }
}

.stat {
    display: flex;
    align-items: center;
    flex: 1 1 100%;
    padding: 24px 0;
    border-bottom: 1px solid $colors__yellow;
    
    &:last-child {
        border-right: 0;
    }

    &__stat {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 0 4px;
        flex: 0 0 37%;
        font-size: 24px;
        font-weight: $fonts__semibold;
        line-height: 32px;
        text-align: center;
        word-break: break-all;
        padding: 0 8px;
    }   

    @include above(tablet) {
        display: block;
        text-align: center;
        flex: 1 1 50%;
        border-bottom: 0;
        border-right: 1px solid $colors__gray--light;
        padding: 0 24px;

        &:nth-child(even) {
            border-right: 0;
        }

        &__stat {
            font-size: 36px;
            font-weight: $fonts__semibold;
            line-height: 48px;
            padding-bottom: 16px;
        }

        &__desc {
            &::before {
                content: '';
                margin-bottom: 18px;
                margin-left: auto;
                margin-right: auto;
                height: 3px;
                width: 72px;
                background-color: $colors__yellow;
                display: block;
            }
        }
    }

    @include above(desktop_plus) {
        flex: 1 1 25%;

        &:nth-child(even) {
            border-right: 1px solid $colors__gray--light;
        }
        &:last-child {
            border: 0;
        }
    }
}

.container {
    .stats-list {
        padding: 0;
    }
}

// override styles for people page only
.people-search {
    // selector-ception
    .section.section--component.stats-list__container {
        border: 0;
    }
}