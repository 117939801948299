

//must match with breakpoints.js
$breakpoints: (
  'mobile':  ( min-width: 480px ),
  'tablet': ( min-width: 720px ),
  'desktop':  ( min-width: 960px ),
  'desktop_plus':  ( min-width: 992px ),
) !default;

@mixin above($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
}

$breakpointsMax: (
  'mobile':  ( max-width: 480px ),
  'tablet': ( max-width: 720px ),
  'desktop':  ( max-width: 960px ),  
  'xl': ( max-width: 1440px )
) !default;

@mixin below($breakpoint) {
  @if map-has-key($breakpointsMax, $breakpoint) {
    @media #{inspect(map-get($breakpointsMax, $breakpoint))} {
      @content;
    }
  }
}
