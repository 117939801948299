.latest-insights {
    padding: 40px 0 60px 0;
    background-color: $colors__white;
    position: relative;
    z-index: 1;

    @include above(tablet) {
        padding: 110px 0 80px 0;
    }
    &__content {
        @include container();
    }
    
    &__title {
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.06em;
        font-weight: $fonts__semibold;
        text-transform: uppercase;
    }
    
    &__insights {
        display: flex;
        flex-wrap: wrap;
        gap: 60px 144px;
        margin-top: 55px;
        margin-bottom: 40px;

        &-list {
            order: 2;
            flex: 1 1 100%;
            @include above(tablet) {
                order: 1;
                flex: 1 1 calc(50% - 144px);
            }
        }
        &-item {
            border-bottom: 1px solid $colors__gray--light;
            padding: 26px 0;
            &:first-child {
                padding-top: 0;
            }
        }
    }

    &__cta {
        text-align: center;

        @include above(mobile) {
            text-align: left;
        }

        &-link {
            @include buttonCta('cyan', 'transparent');
        }
    }

    &__featured-insight {
        order: 1;
        flex: 1 1 100%;
        @include above(tablet) {
            order: 2;
            flex: 1 1 calc(50% - 144px);
        }

        .latest-insights-item {
            background-color: $colors__white;
            box-shadow: 0px 0px 20px 0px rgba(30, 40, 60, 0.15);

            &__body {
                padding: 36px;
            }
        }

        &:hover {
            .latest-insights-item { 
                box-shadow: 0px 0px 20px 0px rgba(30, 40, 60, 0.30);
            }
        }
    }
}