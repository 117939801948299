.featured-card {
    flex: 1 1 100%;
    background-color: $colors__white;
    box-shadow: 0px 0px 20px 0px rgba(30, 40, 60, 0.15);
    transition: $transition__timing $transition__ease all;

    @include above(tablet) {
        flex: 0 1 calc(50% - 20px);
    }

    @include above(desktop_plus) {
        flex: 1 1 calc(25% - 20px);
    }

    &:hover,
    &:active {
        box-shadow: 0px 0px 20px 0px rgba(30, 40, 60, 0.30);
        transition: $transition__timing $transition__ease all;
    }

    &__link {
        width: 100%;
        height: 100%;
        text-decoration: none;
        display: flex;
        flex-direction: column;

        &:hover,
        &:active {
            transition: $transition__timing $transition__ease all;


            .featured-card__cta {
                color: $colors__navy;
            }
            .featured-card__img-container {
                &::before {
                    opacity: 1;
                }
            }
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        &-container {
            position: relative;
            padding-bottom: 50%;
            overflow: hidden;
            width: 100%;
            background-color: $colors__blue;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                height: 100%;
                width: 100%;
                background: linear-gradient(270deg, rgba(18, 27, 40, 0.10) 0%, rgba(18, 27, 40, 0.90) 100%);
                opacity: 0;
                transition: $transition__timing $transition__ease all;
            }
        }    
    }

    &__content {
        padding: 36px;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__title {
        font-weight: $fonts__semibold;
        text-transform: uppercase;
        line-height: 133.5%; 
        letter-spacing: 0.96px;
        margin-bottom: 30px;
    }

    &__text {
        margin-bottom: 36px;
        line-height: 142%;
    }

    &__cta {
        @include textCta();
        margin-top: auto;
        color: $colors__cyan;
    }
}