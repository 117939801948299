.latest-news {
    color: $colors__navy;
    background-color: $colors__white;
    position: relative;
    z-index: 1;

    &:has(+ .featured-events) {
        padding-bottom: 80px;
    }
    
    &__content {
        background-color: #F9A36A;
        @include container();
        padding-top: 40px;
        padding-bottom: 40px;

        @include above(desktop) {
            overflow: hidden;
        }
    }

    &__title {
        position: relative;
        z-index: 1;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.06em;
        font-weight: $fonts__semibold;
        text-transform: uppercase;
        margin-bottom: 60px;
    }

    &__news {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 60px;

        @include above(desktop) {
            margin-left: -24px;
            margin-right: -24px;
        }

        &-item {
            flex: 1 1 100%;
            padding: 40px 0 24px 0;
            border-bottom: 1px solid $colors__white;

            &:last-child {
                border-bottom: 0;
            }

            &:first-child {
                padding-top: 0;
            }

            @include above(desktop) {
                flex: 1 1 calc((100%/3));
                border-right: 1px solid $colors__white;
                border-bottom: 0;
                padding: 0px 24px 12px 24px;

                &:last-child {
                    border-right: 0;
                }
            }
    
        }
    }

    &__cta {
        text-align: center;
        @include above(mobile) {
            text-align: left;
        }

        &-link {
            @include buttonCta('navy', 'transparent');
        }
    }
}