.home-hero {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 100vh;
    position: fixed;
    width: 100%;
    top: 71px; //navbar height;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 7vw; //top position of .home-featured-cards__diag

    @include above(mobile) {
        min-height: 95vh;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(89.71deg, rgba(30, 40, 60, 0.97) 26.31%, rgba(30, 40, 60, 0.9) 43.28%, rgba(18, 27, 45, 0.4) 69.9%);
        z-index: 2;
    }
    &__content {
        @include container();
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        position: relative;
        z-index: 3;
    }

    &__title {
        transform: translateY(50px);
        opacity: 0;
        font-size: 24px;
        line-height: 142%;
        letter-spacing: 0.02em;
        color: $colors__white;
        font-weight: $fonts__light;
        margin-bottom: 60px;
        max-width: 650px;

        p {
            font-size: 24px;
            line-height: 142%;
            letter-spacing: 0.02em;
            font-weight: $fonts__light;
        }

        @include above(mobile) {
            font-size: 33px;
            line-height: 46px;
            p {
                font-size: 33px;
                line-height: 46px;
            }
        }

    }

    &__cta {
        @include buttonCta('orange', 'solid');
        opacity: 0;

        &-container {
            text-align: center;
            @include above(mobile) {
                text-align: left;
            }
        }
    }

    &__video {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;

        &-container {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 1;
        }
    }

    &__img {
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

}