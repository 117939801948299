.people-search-alpha {
    margin: 48px 0 48px 0;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    row-gap: 30px;
    
    @include above(mobile) {
        grid-template-columns: repeat(13, 1fr);
        margin: 48px 0 18px 0;
        row-gap: 30px;
    }

    @include above(tablet) {
        grid-template-columns: repeat(26, 1fr);
        row-gap: 0px;
        margin: 48px 0;
    }
    
    
    &::after {
        content: "";
        flex: auto;
    }
    
    &__letter {
        font-size: 20px;
        line-height: 20px;
        color: $colors__aqua;
        text-align: center;

        @include above(tablet) {
        }

        &:hover {
            text-decoration: underline;
        }

        &--disabled {
            color: $colors__gray--light;
            &:hover {
                text-decoration: none;
            }
        }
    }
}