.regular-dropdown {
  margin: 16px 0;
  position: relative;
  outline: 1px solid $colors__gray--light;
  overflow: hidden;
  @include transition(background-color);
  display: flex;  
  flex-direction: row;
  align-items: center;
  align-content: center;
  min-width: 100px;

  &--active {
    color: $colors__teal;
    outline-color: $colors__teal;
  }

  &__caret {
    pointer-events: none;
    &--active {
      color: $colors__teal--dark;
    }
    &--clickable{
      pointer-events: all;
    }
  }

  &:focus,
  &:hover {
    color: $colors__navy;
    outline: 1px solid $colors__navy;
  }
  
  &::after {    
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 16px;
   
  }
  &__caret {
    cursor: pointer;
    position: absolute;
    right: 14px;  
    top: 16px;  

    &.icon__chevron-thin-down {
      z-index: -1;
    }
  }
  &--arrow {
    &:after {
      @include font-family-icomoon();
      content: $icon__chevron-thin-down;
    }
   
  }
  &--x {
    &:after {
      @include font-family-icomoon();
      content: $icon__close;
    }
   
  }
  &:hover .regular-dropdown__select {
    // color: $colors__teal;
  
  }
  &:hover {
    &::after {
      // color: $colors__teal;
      }
  }

  &__select {
    @extend .font__body2;
    padding: 12px 45px 12px 16px;
    color: $colors__black;
    width: 100%;    
    background: transparent; 
    @include transition(background-color);
    position: relative;
    line-height: 20px;
    &:hover {
      color: $colors__navy;
    }
    option {
      color:black;
    }
    &:focus {
      outline: none;
    }
    &--active {
      color: $colors__teal;
    }
    &:hover {      
      background-color: transparent;
      @include transition(background-color);
    }
  }
  @include above(mobile) {
    margin-right: 24px;
  }

  //in desktop view, these are little dropdowns that have no background color
  @include above(desktop) {   
    width: auto;    

    &::after {
      top:18px;
    }

    &:hover {
      //background-color: $colors__smoke;
    }

    &__select {
      background-color: transparent;      
    }
  }
}