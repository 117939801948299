html, body {
	height: 100%;
	scroll-margin-top: 80px;
}

:target {
	scroll-margin-top: 80px;
}

body {
	margin: 0;
	font-size: 16PX;
	font-family: 'Graphik', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

div, section {
	box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p {
	margin: 0;
	font-size: 1rem;
	font-weight: normal;
}

a, input, button {
	color: inherit;
	display: inline-block;
	box-sizing: border-box;
	-webkit-appearance: none;
	font: inherit;
	text-decoration: none;
	text-align: left;
	cursor: pointer;
	line-height: 1;
	background: rgba(0, 0, 0, 0);
	border: 0;
	padding: 0;
}

input[type="search"] {
	-webkit-appearance: none;
  }
  input[type="search"]::-webkit-search-cancel-button {
	-webkit-appearance: none;
  }

form {
	margin: 0;
}


[v-cloak] {
	display: none !important;
}

ol, ul {
	padding: 0;
	margin:0;
	list-style: none;
}

//reset browser specific styling for select dropdowns (like safari)
select {
	cursor: pointer;
    border: none;
	border-radius: 0;
	margin: 0;      
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	-webkit-appearance:none;
	-moz-appearance:none;
	-ms-appearance: none;
	 -o-appearance: none;
		appearance: none;

	&::-ms-expand {
		display: none;
	}
}
div:active{
  outline: none;
  //border: none;
}

legend {
  padding: 0;
  display: table;
}
fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}
body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}

.main__min-height {
	min-height: calc(100% - 276px);
}