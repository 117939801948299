// reusable color classes defined in .color block

$colors__navy: #1e283c;
$colors__navy--dark: #0b1a2b;

$colors__blue: #156289;
$colors__blue--dark: #0c5273;

$colors__cyan: #039ecc;
$colors__cyan--dark: #048db6;

$colors__teal: #3da3a3;
$colors__teal--dark: #318c89;

$colors__coral: #e06565;
$colors__coral--dark: #c95555;

$colors__yellow: #f9a36a;
$colors__yellow--dark: #f2985a;

$colors__gray: #7D7E81;
$colors__gray--dark: #7d7e81;

$colors__smoke: #e6e7e8;
$colors__lines: #b4b4b4;
$colors__white: #ffffff;
$colors__black: #000000;

$colors__gray--light: #D8D8D8;
$colors__gray--modal: #f7f8f8;

$colors__aqua: #156289;