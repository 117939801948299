.component-card-container {

  //do nothing on mobile
  @include above(mobile) {
  }

  // 2 columns
  @include above(tablet) {
      margin-left: -10px;
      margin-right: -10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
        &--two, &--full  {
            .component-card {
                width: 50%;
            }
        }
        &--three {
            .component-card {
                width: 33%;
            }
        }
    }

  
  // 3 columns
  @include above(desktop) {
        &--two {
            .component-card {
                width: 50%;
            }
        }
        &--three, &--full {
            .component-card {
                width: 33%;
            }
        }
  }

 
}