.latest-news-card {
    height: 100%;
    display: flex;

    &__date {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.04em;
        width: 100%;
        border-bottom: 1.5px solid $colors__navy;
        padding-bottom: 6px;
        margin-bottom: 12px;

        &:empty {
            display: none;
        }
        
        @include above(desktop) {
            min-height: 30px;

            &:empty {
                display: block;
            }
        }
    }

    &__title {
        font-weight: $fonts__semibold;
        font-size: 23px;
        line-height: 34px;
        margin-bottom: 26px;
        line-clamp: 4;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }

    &__cta {
        margin-top: auto;

        &-btn {
            @include textCta();
            color: $colors__navy;
        }

        &-link {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            
            &:hover {
                .latest-news-card__cta-btn {
                    text-decoration: underline;
                }
            }
        }
    }
}