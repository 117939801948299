@keyframes animator {
  0% {background-position: 200% 0%}
100% {background-position: 0% 0%}
}

.pattern-background {
  position: fixed;
  top:0;
  width: 100%;
  height: 100%;

  &__gradient {
    // position: fixed;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #42a3a2 0%, #229fbf 10%, #19769c 20%, #1e283a 50%, #19769c 80%, #229fbf 90%, #42a3a2 100%);
    background-size: 200%;
    animation-duration: 5s;
    animation-name: animator;
    animation-iteration-count: 30;
    animation-timing-function: linear;    
  }

  @supports (-ms-ime-align:auto) {
    &__gradient{
      animation-duration: 0;
      animation-iteration-count: 0;
    }
  }

  &__pattern {
    // position: fixed;
		width: 100%;
		height: 100%;
		background-image: url('/assets/public/static/images/pattern-background.svg');
  }

  &__mask {
    // position: fixed;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(to right, rgba(30, 40, 60, 1) 0%, rgba(30, 40, 60, .5) 75%);
  }
}