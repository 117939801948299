
.related-practices {
  display: flex;
  flex-direction: column;
  width:100%;
  overflow: hidden;

  &__left {
    &__header {
      @extend .font__h2;
      margin-bottom: 1em;
	  
      &.component-container__header {
        margin-bottom: 1em;
      }
    }

    padding-right: 40px;
  }

  &__right-image-wrapper{
    display: none;
    text-align: center;
    vertical-align: top;
  }

  &__accordion-wrapper{
    display: flex;
    flex-direction: column;
  }

  &__right {
    &__practice {
      position: relative;

      &--active {
	      .related-practices__right__practice__header {
          &:after {
            content: $icon__minus;
          }
		    }       
      }

      &__header {
        @extend .font__body;
        @extend .font__body--semibold;
        cursor: pointer;
		    position: relative;
		
        &:after {
          pointer-events: none;
          @include font-family-icomoon();
          content: $icon__plus;
          font-size: 12px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &__body {
        margin: 1em 0;
        visibility: hidden;
        @extend .font__body2;

        &__text{
          margin-bottom: 1em;
        }
      }
    }
  }

  @include above(mobile) {
    &__right-image-wrapper{
      display: block;
    }

    &__right,
    &__left {
      flex: 1;
    }
  }

  @include above(tablet) {
    flex-direction: row;
    
    &__right {
      &__practice {
       &__header {
          &:after {
            font-size: 18px;
          }
        }
      }
    }
  }

  @include above(desktop) {
    &__right {
      &__practice {
		  transition: margin .5s linear;
        &--active{
          margin-bottom:$mobile-vertical-padding;
        
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
