.featured-event-card {
    border-radius: 0 0 20px 20px;
    overflow: hidden;
    background-color: $colors__white;
    height: 100%;
    box-shadow: 0px 0px 20px 0px rgba(30, 40, 60, 0.15);
    transition: .3s ease all;

    &:hover {
        box-shadow: 0px 0px 20px 0px rgba(30, 40, 60, 0.30);
        .featured-event-card__cta {
            background-color: $colors__cyan;
            color: $colors__white;
        }
    }

    &__date {
        color: $colors__white;
        background-color: $colors__blue;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.1em;
        font-weight: $fonts__semibold;
        text-align: center;
        padding: 8px 36px;
        min-height: 40px;
        text-transform: uppercase;
    }

    &__body {
        padding: 36px;
        padding-bottom: 48px;
        color: $colors__navy;
    }

    &__title {
        font-size: 26px;
        line-height: 128%;
        letter-spacing: 0.01em;
        font-weight: $fonts__light;
        margin-top: 22px;
        line-clamp: 4;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }

    &__eyebrow {
        font-size: 16px;
        line-height: 20px;
    }

    &__cta {
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $colors__cyan;
        border-top: 1px solid $colors__cyan;
        text-align: center;
        font-weight: $fonts__semibold;
        margin-top: auto;
        background-color: $colors__white;
        transition: .3s ease all;
        &-btn {
            padding: 16px 36px;
            width: 100%;
            text-align: center;
        }
        &-link {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

}